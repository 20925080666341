module.exports = {
  pathPrefix: `/`,
  siteUrl: `https://castazzo.com`,
  siteTitle: `castazzo.com`,
  siteTitleAlt: `The wisdom of mentors.`,
  siteTitleShort: `castazzo`,
  siteLanguage: `en`,
  siteLogo: `src/images/castazzo512.png`,
  siteDescription: `Castazzo.com - The wisdom of mentors.`,
  siteAuthor: `John Milazzo`,
  siteOrganization: `13jam, llc.`,
  copyright: `Copyright 13jam, llc.  All rights reserved.`,

  // Analytics
  googleTagId: `UA-133507984-1`,

  // social medias
  twitterUrl: `https://twitter.com/castazzo/`,
  twitterUsername: `@castazzo`,

  // offline manifest
  backgroundColor: `#e9e9e9`,
  themeColor: `#121212`,
  icon: `src/images/castazzo512.png`
};

/*
pathPrefix: Prefix for all links. If you deploy your site to example.com/blog your pathPrefix should be "blog"

siteUrl: No trailing slash!
siteTitle:
siteTitleAlt:
siteTitleShort:
siteLogo: Used for SEO and manifest, path to your image you placed in the 'static' folder
*/

/*
Taglines:
"The wisdom of mentors."
"Lessons from great mentors."
"The wisdom of many."
*/

/*
<!doctype html>

<html lang="en">

<head>
<meta charset="utf-8" />
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

<link rel="canonical" href="https://sparktoro.com/about" />

<title>About SparkToro| SparkToro</title>

<meta name="description" content="Learn more about SparkToro and the team behind it.">
*/
