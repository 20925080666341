import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import config from '../config/siteConfig';
import SiteStyle from '../config/siteStyle';
import Header from './Header';
import Footer from './Footer';

const PageStyle = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
  height: 100vh;
`;

const InnerStyle = styled.div`
  grid-area: main;
  margin: 0 auto;
  padding: 3rem;
`;

const PageLayout = ({ children }) => (
  <div>
    <Helmet>
      <title>{config.title}</title>
      <meta name="description" content={config.description} />
    </Helmet>

    <SiteStyle />

    <PageStyle>
      <Header />
      <InnerStyle>
        <div>{children}</div>
      </InnerStyle>
      <Footer />
    </PageStyle>
  </div>
);

export default PageLayout;

PageLayout.propTypes = {
  children: PropTypes.node
};
