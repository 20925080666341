import { createGlobalStyle } from 'styled-components';

const SiteStyle = createGlobalStyle`
html {
 box-sizing: border-box;
}
*, *:before, *:after {
 box-sizing: inherit;
}
body {
  display: grid;
  margin: 0;
  padding: 0;
  border-top-style: solid;
  border-top-width: 4px;
  border-top-color: hsl(215, 96%, 32%);
}
`;

export default SiteStyle;
