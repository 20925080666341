import React from 'react';
import styled from 'styled-components';

import config from '../config/siteConfig';

const StyledFooter = styled.footer`
  grid-area: footer;
  display: flex;
  width: 100%;
  justify-content: center;
  color: darkgray;
`;

const Footer = () => (
  <StyledFooter>
    <h6>{config.copyright}</h6>
  </StyledFooter>
);

export default Footer;
